import * as React from 'react';
import { CityLineSearchContext } from '../SearchContext';
import * as _ from 'lodash';
import { searchLink } from '../../../../lib/utils/link';

type Props = {
}

const CityCheckboxes: React.FC<Props> = () => {
  const { searchParams, normalizedUrlParams, formValues, setFormValues, occupationAscii, counter, cities } = React.useContext(CityLineSearchContext);

  const [elRefs, setElRefs] = React.useState<React.RefObject<HTMLInputElement>[]>([]);

  React.useEffect(() => {
    const refs = cities.map(() => {
      return React.createRef<HTMLInputElement>()
    });
    setElRefs(refs);
  }, [cities, setElRefs]);

  const handleChange = React.useCallback((cityCode: string, checked: boolean) => {
    const newFormValues = Object.assign({}, formValues);

    let formCityCode = _.compact(_.flatten([newFormValues.city_code])) as string[];
    if (checked) {
      formCityCode.push(cityCode);
    } else {
      _.remove(formCityCode, (v) => v != cityCode);
    }
    newFormValues.city_code = formCityCode;

    setFormValues(newFormValues)
  }, [formValues, setFormValues]);

  return (
    <ul className="search_box-modal-content-chk_list">
      {cities.map((city, i) => (
        <li key={i} className="search_box-modal-content-chk_list-item">
          <div className={`search_box-modal-group_link ${!counter || _.get(counter, ['cities', city.code]) ? '' : 'm-disabled'}`}>
            <label className="search_box-modal-group_link-chk_box">
              <input
                ref={elRefs[i]}
                type="checkbox"
                name="city_code[]" value={city.code}
                onChange={(e) => handleChange(city.code, e.target.checked)}
                checked={[formValues.city_code].flat().includes(city.code)}
              />
              <span className="search_box-modal-group_link-chk_style" />
              <span className="search_box-modal-group_link-title">{city.name}</span>
              {counter && (
                <span className="search_box-modal-group_link-count">
                  ({_.get(counter, ['cities', city.code]) || 0})
                </span>
              )}
            </label>
            <a
              aria-label={city.name}
              href={searchLink(
                occupationAscii,
                Object.assign({ city_code: city.code, prefecture_code: city.prefecture_code }, _.omit(searchParams, ['occupation_ascii', 'city_code'])),
                Object.assign({ city_ascii: city.ascii }, _.omit(normalizedUrlParams, ['city_code', 'city_ascii'])))
              }
            >
              <i className="fas fa-chevron-right" />
            </a>
          </div>
        </li>
      ))}
    </ul>
  )
};

export default CityCheckboxes;
