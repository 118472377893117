import { OccupationAsciiType, SearchParamInterface, normalizedUrlParamInterface } from '../interfaces/search';
import * as _ from 'lodash';

export const buildQuery = (searchParams: object): string => {
  return Object.keys(searchParams).map((key) => {
    if (searchParams[key] instanceof Array) {
      return searchParams[key].map((value) => {
        return `${encodeURIComponent(`${key}[]`)}=${encodeURIComponent(value)}`;
      }).join('&');
    } else {
      return `${encodeURIComponent(key)}=${encodeURIComponent(searchParams[key])}`;
    }
  }).join('&');
};

export const prefectureSearchLink = (occupationAscii: OccupationAsciiType, prefectureAscii: string) => {
  return `/${occupationAscii}/${prefectureAscii}`;
};

export const featureSearchLink = (occupationAscii: OccupationAsciiType, featureAscii: string) => {
  return `/${occupationAscii}/${featureAscii}`;
};

export const searchLink = (occupationAscii: OccupationAsciiType, searchParams: SearchParamInterface, normalizedUrlParams: normalizedUrlParamInterface) => {
  // NOTE: searcher/job.rb redirect_urlを踏襲
  if(searchPageParamsExist(searchParams, normalizedUrlParams) ||
     searchPageConditionParamsExist(searchParams, normalizedUrlParams)){
    return `/${occupationAscii}/job/search/?${buildQuery(searchParams)}`;
  }else{
    return buildNormalizedUrl(normalizedUrlParams)
  }
}

const searchPageParamsExist = (searchParams: SearchParamInterface, normalizedUrlParams: normalizedUrlParamInterface) => {
  const searchParamsKeys = ['yearly_salary_min', 'monthly_salary_min', 'daily_salary_min', 'hourly_salary_min', 'word'];

  return (_.intersection(Object.keys(searchParams), searchParamsKeys)).length > 0 ||
  (searchParams.feature_id && !normalizedUrlParams.feature_ascii) ||
  Object.values(searchParams).find((value) => Array.isArray(value))
} 

const searchPageConditionParamsExist = (searchParams: SearchParamInterface, normalizedUrlParams: normalizedUrlParamInterface) => {
  let has_search_condition = false;
  // NOTE: 政令指定都市を持ち、かつ下記パラメーター以外を含む場合、検索クエリを生成する
  if(
    normalizedUrlParams['city_code'] &&
    _.reject(Object.keys(normalizedUrlParams), key => 
      ['prefecture_ascii', 'city_ascii', 'city_code', 'occupation_ascii', 'feature_ascii'].includes(key)
    ).length !== 0
    ){
    has_search_condition = true;
  }
  // NOTE: 沿線・駅ページはoccupation以外のパラメーターは含まない
  ['railroad_line_id', 'station_group_id'].forEach((target) => {
    if(
      searchParams[target] && 
      _.reject(Object.keys(searchParams), key => 
        ['railroad_line_id', 'station_group_id','occupation_id'].includes(key)).length !== 0
    ){
      has_search_condition = true;
    }
  });
  return has_search_condition;
}

const buildNormalizedUrl = (params: normalizedUrlParamInterface) => {
  if (params['city_code'] && params['city_ascii'] && params['prefecture_ascii'] && params['feature_ascii']) {
    return (`/${params['occupation_ascii']}/${params['prefecture_ascii']}/${params['city_ascii']}/${params['feature_ascii']}`);
  } else if (params['city_code']) {
    return (`/${params['occupation_ascii']}/major-city-${params['city_code']}`);
  } else if (params['railroad_line_id']) {
    return (`/${params['occupation_ascii']}/line-${params['railroad_line_id']}`);
  } else if (params['station_group_id']) {
    return (`/${params['occupation_ascii']}/station-${params['station_group_id']}`);
  } else if (params['city_ascii'] && params['prefecture_ascii'] && params['feature_ascii']) {
    return (`/${params['occupation_ascii']}/${params['prefecture_ascii']}/${params['city_ascii']}/${params['feature_ascii']}`);
  } else if (params['prefecture_ascii'] && params['city_ascii']) {
    return (`/${params['occupation_ascii']}/${params['prefecture_ascii']}/${params['city_ascii']}`);
  } else if (params['prefecture_ascii'] && params['feature_ascii']) {
    return (`/${params['occupation_ascii']}/${params['prefecture_ascii']}/${params['feature_ascii']}`);
  } else if (params['prefecture_ascii']) {
    return (`/${params['occupation_ascii']}/${params['prefecture_ascii']}`);
  } else if (params['feature_ascii']) {
    return (`/${params['occupation_ascii']}/${params['feature_ascii']}`);
  }
}
