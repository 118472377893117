import * as React from 'react';
import {
  filterClinicEnvironmentFeatures, filterClinicPolicyFeatures,
  filterMedicalApartmentFeatures,
  filterWorkingConditionFeatures
} from '../../../../lib/utils/search';
import FeatureListItem from './FeatureListItem';
import {PrefectureSearchContext} from '../SearchContext';

type Props = {
  isShow: boolean;
  handleCloseModal: () => void;
}

const FeatureModalContent: React.FC<Props> = (
  {
    isShow,
    handleCloseModal,
  }
) => {
  const {occupationAscii, features} = React.useContext(PrefectureSearchContext);
  return (
    <div className={`search_index-view ${isShow ? 'search_index-view-active' : ''}`}>
    <div className="c-overlay search_index-modal-overlay js-overlay" style={isShow ? {display: 'block'} : {}} onClick={() => handleCloseModal()}/>
      <div className={`search_index-modal ${isShow ? 'search_index-modal-show' : ''}`}>
        <div className="search_index-modal-frame">
          <div className="search_index-modal-body">
            <div className="search_index-modal-head forSp">特徴から選択</div>
            <a aria-label="閉じる" className="forSp search_index-modal-close_btn" onClick={() => handleCloseModal()}>×</a>
            <div className="search_index-modal-content">
              <div className="search_index-modal-content-inner">
                <h4>勤務条件</h4>
                <ul className="search_index-modal-content-chk_list">
                  {filterWorkingConditionFeatures(features).map((feature, i) => (
                    <FeatureListItem key={i} feature={feature}/>
                  ))}
                </ul>
              </div>
              <div className="search_index-modal-content-inner">
                <h4>医院の環境</h4>
                <ul className="search_index-modal-content-chk_list">
                  {filterClinicEnvironmentFeatures(features).map((feature, i) => (
                    <FeatureListItem key={i} feature={feature}/>
                  ))}
                </ul>
              </div>
              <div className="search_index-modal-content-inner">
                <h4>診療内容</h4>
                <ul className="search_index-modal-content-chk_list">
                  {filterMedicalApartmentFeatures(features).map((feature, i) => (
                    <FeatureListItem key={i} feature={feature}/>
                  ))}
                </ul>
              </div>
              {occupationAscii == 'dr' && (
                <div className="search_index-modal-content-inner">
                  <h4>診療方針</h4>
                  <ul className="search_index-modal-content-chk_list">
                    {filterClinicPolicyFeatures(features).map((feature, i) => (
                      <FeatureListItem key={i} feature={feature}/>
                    ))}
                  </ul>
                </div>
              )}
            </div>
            <div className="search_index-modal-foot">
              <div className="search_index-modal-foot-content">
                <div className="search_index-modal-foot--btn_wrap">
                  <button type="submit" name="button" className="search_index-modal-foot--submit_btn">求人を検索する</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default FeatureModalContent;
