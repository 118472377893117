import * as React from 'react';
import * as _ from 'lodash';
import { FeatureModel } from '../../../../lib/interfaces/models';
import { searchLink } from '../../../../lib/utils/link';
import { PrefectureSearchContext } from '../SearchContext';

type Props = {
  feature: FeatureModel;
}

const FeatureListItem: React.FC<Props> = ({ feature }) => {
  const { occupationAscii, searchParams, normalizedUrlParams } = React.useContext(PrefectureSearchContext);

  return (
    <li className="search_index-modal-content-chk_list-item">
      <div className="search_index-modal-group_link">
        <label className="search_index-modal-group_link-chk_box">
          <input
            type="checkbox"
            name="feature_id[]"
            value={feature.id}
            defaultChecked={[searchParams.feature_id].flat().includes(feature.id)}
          />
          <span className="search_index-modal-group_link-chk_style" />
          <span className="search_index-modal-group_link-title">{feature.name}</span>
        </label>
        <a
          aria-label={feature.name}
          href={searchLink(
            occupationAscii,
            Object.assign({ feature_id: feature.id }, _.omit(searchParams, ['occupation_ascii', 'feature_id'])),
            Object.assign({ occupation_ascii: normalizedUrlParams['occupation_ascii'], feature_ascii: feature.ascii }, _.omit(normalizedUrlParams, ['feature_ascii'])),
          )}
        >
          <i className="fas fa-chevron-right" />
        </a>
      </div>
    </li>
  )
};

export default FeatureListItem;
