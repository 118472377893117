import * as React from 'react';

type Props = {
  showable: boolean;
  isClose: () => void;
  gaClickEvent?: () => void;
  children;
}

const Modal: React.FC<Props> = ({
  showable,
  isClose,
  gaClickEvent,
  children,
}) => {
  if (!showable) {
    return null;
  }

  return (
    <div className="c-modal-mask">
      <div className="c-modal-wrapper">
        <div className="c-modal-container">
          <div className="c-modal-body">
            {children}
          </div>

          <div className="c-modal-close">
            <button
              type="button"
              className="c-modal-close-button"
              onClick={() => {
                isClose();
                if (gaClickEvent) {
                  gaClickEvent();
                }
              }}
            >
              <span className="c-modal-close-button--icon"><i className="fas fa-times-circle"></i></span><span>閉じる</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
};

export default Modal;
