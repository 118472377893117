import * as React from 'react';
import * as _ from 'lodash';
import {CounterInterface, OccupationAsciiType, SearchPrefectureOptionInterface} from '../../../lib/interfaces/search';
import {prefectureSearchLink} from '../../../lib/utils/link';

type Props = {
  occupationAscii: OccupationAsciiType;
  prefectures: SearchPrefectureOptionInterface[];
  counter?: CounterInterface;
}

export const initialProps: Props = {
  occupationAscii: 'dh',
  prefectures: [],
};

const PrefectureSearchButton: React.FC<Props> = ({occupationAscii, prefectures, counter}) => {
  const [isModalOpen, toggleModal] = React.useState(false);

  React.useEffect(() => {
    if (window.parent.screen.width <= 600) {
      if (isModalOpen) {
        document.body.style.cssText = 'overflow: hidden;';
      } else {
        document.body.style.cssText = '';
      }
    }
  }, [isModalOpen]);

  return (
    <React.Fragment>
      <div className="jobList-head--result-pref">
        <a onClick={() => toggleModal(true)}>都道府県を変更</a>
      </div>
      <div className="c-overlay search_index-modal-overlay search_index-use_modal-overlay js-overlay" style={isModalOpen ? {display: 'block'} : {}} onClick={() => toggleModal(false)}/>
      <div className={`search_index-modal search_index-use_modal ${isModalOpen ? 'search_index-modal-show' : ''}`}>
        <div className="search_index-modal-frame">
          <div className="search_index-modal-body">
            <div className="search_index-modal-head"><span className="search_index-modal-head--title">都道府県を変更</span>
            </div>
            <a aria-label="閉じる" className="search_index-modal-close_btn" onClick={() => toggleModal(false)}>×</a>
            <div className="search_index-modal-content">
              <div className="search_index">
                <ul className="search_index-view--area_list search_index-view--area_list-pref_change">
                  {prefectures.map((optionPrefecture, i) => (
                    <li key={i} className="search_index-view--area_list--item">
                      <span className="search_index-view--area_list--item--ttl">{optionPrefecture.area_name}</span>
                      <div className="search_index-view--area_list--item--body">
                        <ul className="search_index-view--area_list--item--pref_list">
                          {optionPrefecture.prefectures.map((prefecture, j) => (
                            <li
                              key={j}
                              className={`search_index-view--area_list--item--pref_list--item ${!counter || _.get(counter, ['prefectures', prefecture.code]) ? '' : 'm-disabled'}`}
                            >
                              <a aria-label={prefecture.name} href={prefectureSearchLink(occupationAscii, prefecture.ascii)}>
                                <span className="search_index-view--area_list--item--pref_list--item-title">{prefecture.name}</span>
                                {counter && (
                                  <span className="search_index-view--area_list--item--pref_list--item-count">
                                ({_.get(counter, ['prefectures', prefecture.code]) || 0})
                              </span>
                                )}
                                <span className="search_index-view--area_list--item--pref_list--item-arrow">
                                  <i className="fas fa-chevron-right"/>
                                </span>
                              </a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PrefectureSearchButton;
