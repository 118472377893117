import * as React from 'react';
import * as _ from 'lodash';
import {RailroadStationModel} from '../../../../lib/interfaces/models';
import {searchLink} from '../../../../lib/utils/link';
import {CityLineSearchContext} from '../SearchContext';

type Props = {
  railroadStation: RailroadStationModel;
}

const StationListItem: React.FC<Props> = ({railroadStation}) => {
  const {searchParams, normalizedUrlParams, occupationAscii, formValues, setFormValues, counter} = React.useContext(CityLineSearchContext);

  const count = React.useMemo(() => {
    return _.get(counter, ['railroad_stations', railroadStation.station_group_id]) || 0;
  }, [counter, railroadStation]);

  const handleChange = React.useCallback((stationGroupId: number) => {
    const newFormValues = Object.assign({}, formValues);

    const index = _.indexOf([newFormValues.station_group_id].flat(), stationGroupId);
    let formStationGroupId = _.compact(_.flatten([newFormValues.station_group_id])) as number[];
    if (index < 0) {
      formStationGroupId.push(stationGroupId);
    } else {
      _.remove(formStationGroupId, (v) => v === stationGroupId)
    }
    newFormValues.station_group_id = formStationGroupId;

    setFormValues(newFormValues)
  }, [formValues, setFormValues]);

  return (
    <li className="search_box-modal-content-chk_list-item">
      <div className={`search_box-modal-group_link ${!counter || count ? '' : 'm-disabled'}`}>
        <label className="search_box-modal-group_link-chk_box">
          <input
            type="checkbox"
            name="station_group_id[]"
            value={railroadStation.station_group_id}
            onChange={() => handleChange(railroadStation.station_group_id)}
            checked={[formValues.station_group_id].flat().includes(railroadStation.station_group_id)}
          />
          <span className="search_box-modal-group_link-chk_style"/>
          <span className="search_box-modal-group_link-title">{railroadStation.name}駅</span>
          {counter && (
            <span className="search_box-modal-group_link-count">
              ({count})
            </span>
          )}
        </label>
        <a
          aria-label={`${railroadStation.name}駅`}
          href={
            searchLink(
              occupationAscii,
              Object.assign({station_group_id: railroadStation.station_group_id}, _.omit(searchParams, ['occupation_ascii', 'prefecture_code', 'city_code', 'railroad_line_id', 'station_group_id'],)),
              Object.assign({station_group_id: railroadStation.station_group_id}, _.omit(normalizedUrlParams, ['prefecture_ascii', 'city_ascii', 'city_code', 'railroad_line_id', 'station_group_id'])),
              
            )
          }
        >
          <i className="fas fa-chevron-right"/>
        </a>
      </div>
    </li>
  )
};

export default StationListItem;
