import * as React from 'react';
import SearchAccordion from './SearchAccordion';
import { CityModel } from '../../../../lib/interfaces/models';
import { CityLineSearchContext } from '../SearchContext';
import * as _ from 'lodash';
import { searchLink } from '../../../../lib/utils/link';

type Props = {
  parentCity?: CityModel;
  cities: CityModel[];
}

const CityCheckboxesWithAccordion: React.FC<Props> = ({ parentCity, cities }) => {
  const { searchParams, normalizedUrlParams, formValues, setFormValues, occupationAscii, counter } = React.useContext(CityLineSearchContext);

  const [parentCityRef] = React.useState<React.RefObject<HTMLInputElement>>(React.createRef<HTMLInputElement>());
  const [elRefs, setElRefs] = React.useState<React.RefObject<HTMLInputElement>[]>([]);

  React.useEffect(() => {
    const refs = cities.map(() => {
      return React.createRef<HTMLInputElement>()
    });
    setElRefs(refs);
  }, [cities, setElRefs]);

  const handleChange = React.useCallback((cityCode: string, checked: boolean) => {
    const newFormValues = Object.assign({}, formValues);

    let formCityCode = _.compact(_.flatten([newFormValues.city_code])) as string[];
    if (checked) {
      formCityCode.push(cityCode);
    } else {
      if (parentCityRef.current) {
        formCityCode = _.filter(formCityCode, (code) => ![parentCityRef.current?.value, cityCode].includes(code));
      } else {
        formCityCode = _.filter(formCityCode, (code) => code != cityCode);
      }
    }
    newFormValues.city_code = formCityCode;

    setFormValues(newFormValues)
  }, [formValues, setFormValues, parentCityRef]);

  const handleSelectAll = React.useCallback((cityCode: string, checked: boolean) => {
    const cityCodes = [cityCode];
    elRefs.forEach(elRef => {
      if (elRef.current?.type?.toLowerCase() == 'checkbox') {
        elRef.current.checked = checked;
        cityCodes.push(elRef.current.value);
      }
    });

    const newFormValues = Object.assign({}, formValues);
    if (checked) {
      newFormValues.city_code = _.uniq(_.compact(_.flatten([cityCodes, newFormValues.city_code as string | string[]])));
    } else {
      newFormValues.city_code = _.filter([newFormValues.city_code].flat(), (code) => !cityCodes.includes(code));
    }
    setFormValues(newFormValues);
  }, [elRefs, formValues, setFormValues]);

  const hasSelectedCity: boolean = React.useMemo(() => {
    return !!_.find(cities, city => _.flatten([searchParams.city_code]).includes(city.code));
  }, [cities, searchParams]);

  return (
    <SearchAccordion title={parentCity ? parentCity.name : 'その他の市区町村'} isInitialOpen={hasSelectedCity}>
      <ul className="search_box-modal-content-chk_list">
        {parentCity && (
          <li className="search_box-modal-content-chk_list-item">
            <div className={`search_box-modal-group_link ${!counter || _.get(counter, ['parent_cities', parentCity.code]) ? '' : 'm-disabled'}`}>
              <label className="search_box-modal-group_link-chk_box">
                <input
                  ref={parentCityRef}
                  type="checkbox"
                  name="city_code[]"
                  value={parentCity.code}
                  onChange={(e) => handleSelectAll(e.target.value, e.target.checked)}
                  checked={[formValues.city_code].flat().includes(parentCity.code)}
                />
                <span className="search_box-modal-group_link-chk_style" />
                <span className="search_box-modal-group_link-title">{parentCity.name}すべて</span>
                {counter && (
                  <span className="search_box-modal-group_link-count">
                    ({_.get(counter, ['parent_cities', parentCity.code]) || 0})
                  </span>
                )}
              </label>
              <a
                aria-label={parentCity.name}
                href={searchLink(
                  occupationAscii,
                  Object.assign({ city_code: parentCity.code }, _.omit(searchParams, ['occupation_ascii', 'city_code'])),
                  Object.assign({ city_code: parentCity.code, city_ascii: parentCity.ascii }, _.omit(normalizedUrlParams, ['city_code', 'city_ascii'])),
                )}
              >
                <i className="fas fa-chevron-right" />
              </a>
            </div>
          </li>
        )}
        {cities.map((city, i) => (
          <li key={i} className="search_box-modal-content-chk_list-item">
            <div className={`search_box-modal-group_link ${!counter || _.get(counter, ['cities', city.code]) ? '' : 'm-disabled'}`}>
              <label className="search_box-modal-group_link-chk_box">
                <input
                  ref={elRefs[i]}
                  type="checkbox"
                  name="city_code[]" value={city.code}
                  onChange={(e) => handleChange(city.code, e.target.checked)}
                  checked={[formValues.city_code].flat().includes(city.code) || [formValues.city_code].flat().includes(city.parent_city_code)}
                />
                <span className="search_box-modal-group_link-chk_style" />
                <span className="search_box-modal-group_link-title">{city.name}</span>
                {counter && (
                  <span className="search_box-modal-group_link-count">
                    ({_.get(counter, ['cities', city.code]) || 0})
                  </span>
                )}
              </label>
              <a
                aria-label={city.name}
                href={searchLink(
                  occupationAscii,
                  Object.assign({ city_code: city.code }, _.omit(searchParams, ['occupation_ascii', 'city_code'])),
                  Object.assign({ city_ascii: city.ascii }, _.omit(normalizedUrlParams, ['city_code', 'city_ascii'])))
                }
              >
                <i className="fas fa-chevron-right" />
              </a>
            </div>
          </li>
        ))}
      </ul>
    </SearchAccordion>
  )
};

export default CityCheckboxesWithAccordion;
