import {
  SearchTypeInterface,
  SearchParamInterface,
  SearchCityModel,
  SearchPrefectureOptionInterface
} from '../interfaces/search';
import {CityModel, FeatureModel, PrefectureModel, RailroadLineModel} from '../interfaces/models';
import * as _ from 'lodash';

export const getSearchType = (searchParams: SearchParamInterface): SearchTypeInterface => {
  if (searchParams.station_group_id) {
    return 'line-station';
  } else if (searchParams.railroad_line_id) {
    return 'station';
  } else if (searchParams.city_code) {
    return 'city';
  } else {
    return 'city-line';
  }
};

export const filterFeatures = (features: FeatureModel[], featureId?: number | number[], onlyEmployment: boolean = false): FeatureModel[] => {
  if (!featureId) {
    return [];
  }

  if (onlyEmployment) {
    return filterEmploymentFeatures(features).filter((feature) => {
      return [featureId].flat().includes(feature.id);
    });
  }

  return features.filter(feature => {
    return ![4,8].includes(feature.feature_category_id)
  }).filter((feature) => {
    return [featureId].flat().includes(feature.id);
  })
};

export const filterEmploymentFeatures = (features: FeatureModel[]): FeatureModel[] => {
  return features.filter(feature => [4,8].includes(feature.feature_category_id));
};

export const filterWorkingConditionFeatures = (features: FeatureModel[]): FeatureModel[] => {
  return features.filter(feature => [1,5].includes(feature.feature_category_id));
};

export const filterClinicEnvironmentFeatures = (features: FeatureModel[]): FeatureModel[] => {
  return features.filter(feature => [2,6].includes(feature.feature_category_id));
};

export const filterMedicalApartmentFeatures = (features: FeatureModel[]): FeatureModel[] => {
  return features.filter(feature => [3,7].includes(feature.feature_category_id));
};

export const filterClinicPolicyFeatures = (features: FeatureModel[]): FeatureModel[] => {
  return features.filter(feature => feature.feature_category_id === 9);
};

export const filterCities = (cities: SearchCityModel[], cityCode?: string | string[]): CityModel[] => {
  if (!cityCode) {
    return [];
  }
  const targetCityCode = [cityCode].flat();
  const parent_cities = cities.filter((city) => {
    return city.city_type == 'major' && targetCityCode.includes(city.code)
  });
  const child_cities = cities.filter((city) => {
    const parentCityCodes = parent_cities.map((parent_city) => parent_city.code);
    return targetCityCode.includes(city.code) && city.city_type != 'major' && !parentCityCodes.includes(city.parent_city_code || '')
  });

  return parent_cities.concat(child_cities);
};

export const filterPrefectures = (prefectures: SearchPrefectureOptionInterface[], prefectureCode?: string): PrefectureModel[] => {
  if (!prefectureCode) {
    return [];
  }
  const prefs = _.flatten(_.map(prefectures, pref => pref.prefectures));

  return _.filter(prefs, pref => pref.code === prefectureCode);
};

export const buildLineConditionTexts = (railroadLines: RailroadLineModel[], searchParams: SearchParamInterface): string[] => {
  return railroadLines.filter(line => {
    return [searchParams.railroad_line_id].flat().includes(line.id)
  }).map(line => line.name)
};

export const buildStationConditionTexts = (railroadLines: RailroadLineModel[], searchParams: SearchParamInterface): string[] => {
  const stations = _.flatten(_.map(railroadLines, (line) => {
    return line.railroad_stations || []
  }));

  const stationTexts = stations.filter(station => {
    return [searchParams.station_group_id].flat().includes(station.station_group_id)
  }).map(station => `${station.name}駅`);

  return _.uniq(stationTexts);
};
