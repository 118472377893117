import * as React from 'react';
import Modal from '../shared/Modal';

export type Props = {
  desired_conditions_url: string;
};

const PartTimeSurveyModal: React.FC<Props> = ({
  desired_conditions_url,
}) => {
  const [showable, setShowable] = React.useState<boolean>(true);

  return (
    <Modal
      showable={showable}
      isClose={() => setShowable(false)}
    >
      <div className="comp_modal_part_time_survey_wrap">
        <h3>歯科衛生士求人</h3>
        <img src="/static/img/dh/lp/comp_modal_img_LINE.jpg" alt="DH完了画面モーダルイメージ"></img>
        <a href={desired_conditions_url} target="_blank">
          <img src='/static/img/dh/lp/step2.png' alt="希望条件を入力する"></img>
        </a>
        <div className='comp_modal_part_time_survey_text_wrap'>
          <div>
            ご希望条件をご入力いただいた方から
          </div>
          <div>
            【ご優先的】に求人をご案内します！
          </div>
          <div>
            お電話で希望条件をお伺いできた方は
          </div>
          <div>
            【2営業日】までに求人をご紹介します！
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PartTimeSurveyModal;
