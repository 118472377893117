import * as React from 'react';
import {SearchCityModel} from '../../../../lib/interfaces/search';
import {CityLineSearchContext} from '../SearchContext';
import CityCheckboxes from './CityCheckboxes';
import CityCheckboxesWithAccordion from './CityCheckboxesWithAccordion';

type Props = {}

const CityModalContent: React.FC<Props> = () => {
  const {cities, searchParams} = React.useContext(CityLineSearchContext);

  const majorCities = React.useMemo(() => {
    const targetCities: SearchCityModel[] = []
    cities.forEach((city) => {
      if (city.city_type == 'major') {
        city.child_cities = cities.filter((c) => {
          return c.city_type === 'major_child' && c.parent_city_code === city.code
        });
        targetCities.unshift(city);
      }
    });
    return targetCities;
  }, [cities]);

  const otherCities = React.useMemo(() => {
    return cities.filter((city) => {
      return city.city_type != 'major' && city.city_type != 'major_child'
    });
  }, [cities]);

  return (
    <React.Fragment>
      <input type={'hidden'} name={'prefecture_code'} value={searchParams.prefecture_code || cities[0].prefecture_code}/>
      {majorCities.length > 0 ? (
        <React.Fragment>
          {majorCities.map((majorCity, i) => (
            <CityCheckboxesWithAccordion key={i} parentCity={majorCity} cities={majorCity.child_cities}/>
          ))}
          <CityCheckboxesWithAccordion cities={otherCities}/>
        </React.Fragment>
      ) : (
        <CityCheckboxes/>
      )}
    </React.Fragment>
  )
};

export default CityModalContent;
