import * as React from 'react';
import SearchModal from './SearchModal';
import CityModalContent from './CityModalContent';
import LineModalContent from './LineModalContent';
import EmploymentModalContent from './EmploymentModalContent';
import FeatureModalContent from './FeatureModalContent';
import {SearchModalContentTypeInterface, SearchTypeInterface} from '../../../../lib/interfaces/search';
import StationModalContent from './StationModalContent';
import LineStationModalContent from './LineStationModalContent';

type Props = {
  searchType: SearchTypeInterface;
  isModalOpen: boolean;
  searchModalContentType: SearchModalContentTypeInterface | undefined;
  handleModalClose: () => void;
}

const SearchModals: React.FC<Props> = (
  {searchType, isModalOpen, searchModalContentType, handleModalClose}
) => {
  return (
    <React.Fragment>
      {['city', 'city-line'].includes(searchType) && (
        <SearchModal
          isOpen={isModalOpen && searchModalContentType === 'city'}
          searchModalContentType={searchModalContentType}
          handleModalClose={handleModalClose}
        >
          <CityModalContent/>
        </SearchModal>
      )}
      {['city', 'line-station'].includes(searchType) && (
        <SearchModal
          isOpen={isModalOpen && searchModalContentType === 'line-station'}
          searchModalContentType={searchModalContentType}
          handleModalClose={handleModalClose}
        >
          <LineStationModalContent/>
        </SearchModal>
      )}
      {searchType === 'station' && (
        <SearchModal
          isOpen={isModalOpen && searchModalContentType === 'station'}
          searchModalContentType={searchModalContentType}
          handleModalClose={handleModalClose}
        >
          <StationModalContent/>
        </SearchModal>
      )}
      {searchType === 'city-line' && (
        <SearchModal
          isOpen={isModalOpen && searchModalContentType === 'line'}
          searchModalContentType={searchModalContentType}
          handleModalClose={handleModalClose}
        >
          <LineModalContent/>
        </SearchModal>
      )}
      <SearchModal
        isOpen={isModalOpen && searchModalContentType === 'employment'}
        searchModalContentType={searchModalContentType}
        handleModalClose={handleModalClose}>
        <EmploymentModalContent/>
      </SearchModal>
      <SearchModal
        isOpen={isModalOpen && searchModalContentType === 'feature'}
        searchModalContentType={searchModalContentType}
        handleModalClose={handleModalClose}>
        <FeatureModalContent/>
      </SearchModal>
    </React.Fragment>
  )
};

export default SearchModals;
