import * as React from 'react';
import * as _ from 'lodash';
import {RailroadLineModel} from '../../../../lib/interfaces/models';
import {searchLink} from '../../../../lib/utils/link';
import {CityLineSearchContext} from '../SearchContext';

type Props = {
  railroadLine: RailroadLineModel;
}

const LineListItem: React.FC<Props> = ({railroadLine}) => {
  const {searchParams, normalizedUrlParams, occupationAscii, formValues, setFormValues, counter} = React.useContext(CityLineSearchContext);

  const count = React.useMemo(() => {
    return _.get(counter, ['railroad_lines', railroadLine.id]) || 0;
  }, [counter, railroadLine]);
  const handleChange = React.useCallback((railroadLineId: number, checked: boolean) => {
    const newFormValues = Object.assign({}, formValues);

    let formRailroadLineId = _.compact(_.flatten([newFormValues.railroad_line_id])) as number[];
    if (checked) {
      formRailroadLineId.push(railroadLineId);
    } else {
      _.remove(formRailroadLineId, (v) => v === railroadLineId)
    }
    newFormValues.railroad_line_id = formRailroadLineId;

    setFormValues(newFormValues)
  }, [formValues, setFormValues]);

  return (
    <li className="search_box-modal-content-chk_list-item">
      <div className={`search_box-modal-group_link ${!counter || count ? '' : 'm-disabled'}`}>
        <label className="search_box-modal-group_link-chk_box">
          <input
            type="checkbox"
            name="railroad_line_id[]"
            value={railroadLine.id}
            onChange={(e) => handleChange(railroadLine.id, e.target.checked)}
            checked={[formValues.railroad_line_id].flat().includes(railroadLine.id)}
          />
          <span className="search_box-modal-group_link-chk_style"/>
          <span className="search_box-modal-group_link-title">{railroadLine.name}</span>
          {counter && (
            <span className="search_box-modal-group_link-count">
              ({count})
            </span>
          )}
        </label>
        <a
          aria-label={railroadLine.name}
          href={
            searchLink(
              occupationAscii,
              Object.assign({ railroad_line_id: railroadLine.id }, _.omit(searchParams, ['occupation_ascii', 'prefecture_code', 'city_code', 'railroad_line_id', 'station_group_id'])),
              Object.assign({ railroad_line_id: railroadLine.id }, _.omit(normalizedUrlParams, ['prefecture_ascii', 'city_code', 'city_ascii', 'railroad_line_id', 'station_group_id'])),
            )
          }
        >
          <i className="fas fa-chevron-right"/>
        </a>
      </div>
    </li>
  )
};

export default LineListItem;
