/* eslint react-hooks/rules-of-hooks: 0 */
const ReactRailsUJS = require('react_ujs');
const componentRequireContext = require.context('../components/site', true);
ReactRailsUJS.useContext(componentRequireContext);

window.onload = function() {
  document.addEventListener('submit', (e) => {
    const form = e.target as HTMLFormElement;
    if (form.method === 'post') {
      const submits = form.querySelectorAll<HTMLInputElement | HTMLButtonElement>('[type=submit]');
      submits.forEach((element) => {
        element.disabled = true;
      });
    }
  });

  const timer = window.setInterval(function () {
    const images = document.querySelectorAll<HTMLImageElement>('img.lazyload[data-src]');

    if (!images.length) {
      clearInterval(timer);
      return false;
    }
    const windowY = window.scrollY + window.innerHeight;
    images.forEach((image) => {
      const clientRect = image.getBoundingClientRect() ;
      if ((clientRect.y - (window.innerHeight / 3)) < windowY) {
        const src = image.dataset.src as string;
        image.setAttribute('src', src);
        delete image.dataset.src;
      }
    });
  }, 100);
};

