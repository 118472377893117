import * as React from 'react';
import * as _ from 'lodash';
import {CityLineSearchContext} from '../SearchContext';
import StationListItem from './StationListItem';
import {PrefectureModel} from '../../../../lib/interfaces/models';
import {prefectureSearchLink} from '../../../../lib/utils/link';

type Props = {
}

const StationModalContent: React.FC<Props> = () => {
  const {railroadLines, prefectures, searchParams, occupationAscii} = React.useContext(CityLineSearchContext);

  let linePrefectures: PrefectureModel[] = React.useMemo(() => {
    if (!searchParams.railroad_line_id) {
      return [];
    }
    const prefCodes = _.uniq(_.map(_.flatMap(railroadLines, (line) => line.railroad_stations || []),
      (station) => station.prefecture_code));

    return _.filter(_.map(_.flatMap(prefectures, (pref) => pref.prefectures)),
      (pref) => prefCodes.includes(pref.code));
  }, [searchParams.railroad_line_id, prefectures, railroadLines]);

  return (
    <React.Fragment>
      {railroadLines.map((railroadLine, i) => (
        <div key={i} className="search_box-modal-content-inner">
          <h4>{railroadLine.name}</h4>
          <input type={'hidden'} name={'railroad_line_id[]'} value={railroadLine.id}/>
          <ul className="search_box-modal-content-chk_list">
            {railroadLine.railroad_stations?.map((station, i) => (
              <StationListItem key={i} railroadStation={station}/>
            ))}
          </ul>
        </div>
      ))}
      {linePrefectures && (
        <div className="search_box-modal-content-inner">
          <h4>都道府県から沿線を変更</h4>
          <ul className="search_box-modal-content-chk_list">
            {linePrefectures.map((prefecture, i) => (
              <li key={i} className="search_box-modal-content-chk_list-item">
                <a href={prefectureSearchLink(occupationAscii, prefecture.ascii)}>
                  <div className="search_box-modal-group_link">
                    <label className="search_box-modal-group_link-chk_box">
                      <span className="search_box-modal-group_link-title">{prefecture.name}</span>
                    </label>
                  </div>
                </a>
              </li>
            ))}
          </ul>
        </div>
      )}
    </React.Fragment>
  )
};

export default StationModalContent;
