import * as React from 'react';
import * as _ from 'lodash';
import {
  SalaryChoiceInterface,
  SearchParamInterface,
  normalizedUrlParamInterface,
  SearchModalContentTypeInterface,
  SearchCityModel,
  SearchPrefectureOptionInterface,
  OccupationAsciiType, CounterInterface,
} from '../../../lib/interfaces/search';
import {
  FeatureModel,
  RailroadLineModel,
} from '../../../lib/interfaces/models';
import {
  filterCities,
  filterFeatures,
  getSearchType,
  buildStationConditionTexts,
  buildLineConditionTexts,
  filterPrefectures,
} from '../../../lib/utils/search';
import SearchCondition from './city_line/SearchCondition';
import { CityLineSearchContext } from './SearchContext';
import SearchModals from './city_line/SearchModals';

export type Props = {
  occupationAscii: OccupationAsciiType;
  searchParams: SearchParamInterface;
  normalizedUrlParams: normalizedUrlParamInterface;
  prefectures: SearchPrefectureOptionInterface[];
  cities: SearchCityModel[];
  features: FeatureModel[];
  railroadLines: RailroadLineModel[];
  salaryChoices: SalaryChoiceInterface;
  counter?: CounterInterface;
}

export const initialProps: Props = {
  occupationAscii: 'dh',
  searchParams: {},
  normalizedUrlParams: {},
  prefectures: [],
  cities: [],
  features: [],
  railroadLines: [],
  salaryChoices: { yearly_salary_min: [], monthly_salary_min: [], daily_salary_min: [], hourly_salary_min: [] }
};

const CityLineSearchForm: React.FC<Props> = (
  {
    occupationAscii,
    searchParams,
    normalizedUrlParams,
    prefectures,
    cities,
    features,
    railroadLines,
    salaryChoices,
    counter,
  }
) => {
  const searchType = React.useMemo(() => {
    return getSearchType(searchParams);
  }, [searchParams]);
  const [formValues, setFormValues] = React.useState(searchParams);
  const [isModalOpen, toggleModal] = React.useState(false);
  const [searchModalContentType, setSearchModalContentType] = React.useState<SearchModalContentTypeInterface | undefined>();

  const salaryConditionTexts = React.useMemo(() => {
    const texts: string[] = [];
    ['yearly_salary_min', 'monthly_salary_min', 'daily_salary_min', 'hourly_salary_min'].forEach((key) => {
      if (searchParams[key] && salaryChoices[key]) {
        const value = _.find(salaryChoices[key], (v) => v[1] === `${searchParams[key]}`);
        if (value) {
          texts.push(value[0])
        }
      }
    });

    return texts;
  }, [searchParams, salaryChoices]);
  const featureConditionTexts = React.useMemo(() => {
    return filterFeatures(features, searchParams.feature_id).map(feature => feature.name);
  }, [features, searchParams]);
  const employmentConditionTexts = React.useMemo(() => {
    return filterFeatures(features, searchParams.feature_id, true).map(feature => feature.name);
  }, [features, searchParams]);
  const cityConditionTexts = React.useMemo(() => {
    return filterCities(cities, searchParams.city_code).map((city) => city.name);
  }, [cities, searchParams]);
  const lineConditionTexts = React.useMemo(() => {
    return buildLineConditionTexts(railroadLines, searchParams);
  }, [railroadLines, searchParams]);
  const stationConditionTexts = React.useMemo(() => {
    return buildStationConditionTexts(railroadLines, searchParams);
  }, [railroadLines, searchParams]);

  const handleModalOpen = React.useCallback((type: SearchModalContentTypeInterface) => {
    toggleModal(true);
    setSearchModalContentType(type);
    if (window.parent.screen.width <= 600) {
      document.body.style.cssText = 'overflow: hidden;';
    }
  }, [setSearchModalContentType, toggleModal]);

  const handleModalClose = React.useCallback(() => {
    toggleModal(false);
    setSearchModalContentType(undefined);
    setFormValues(searchParams);
    if (window.parent.screen.width <= 600) {
      document.body.style.cssText = '';
    }
  }, [searchParams, setFormValues, setSearchModalContentType, toggleModal]);

  React.useEffect(() => {
    if (isModalOpen) {
      document.body.style.cssText = 'overflow: hidden;';
    } else {
      document.body.style.cssText = '';
    }
  }, [isModalOpen]);

  return (
    <CityLineSearchContext.Provider
      value={{
        occupationAscii: occupationAscii,
        searchParams: searchParams,
        normalizedUrlParams: normalizedUrlParams,
        prefectures: prefectures,
        features: features,
        salaryChoices: salaryChoices,
        cities: cities,
        railroadLines: railroadLines,
        formValues: formValues,
        setFormValues: setFormValues,
        counter: counter,
      }}
    >
      <form action={`/${occupationAscii}/job/search`} method={'get'}>
        <input type={'hidden'} name={'prefecture_code'} value={searchParams.prefecture_code} />
        <div className="search_box">
          {searchType === 'city-line' ? (
            <div className="search_box--area">
              <div className="search_box--area--item">
                <a onClick={() => handleModalOpen('city')} className="search_box--area--item-link">
                  <span className="search_box--area--item-icon"><i className="fas fa-map-marker-alt" /></span>
                  <span className="search_box--area--item-ttl">市区町村から選択</span>
                </a>
              </div>
              <div className="search_box--area--item">
                <a onClick={() => handleModalOpen('line')} className="search_box--area--item-link">
                  <span className="search_box--area--item-icon"><i className="fas fa-train" /></span>
                  <span className="search_box--area--item-ttl">沿線から選択</span>
                </a>
              </div>
            </div>
          ) : ''}
          <div className="search_box--conditions">
            <ul className="search_box--conditions--list">
              {searchType === 'city' ? (
                <li className="search_box--conditions--list-item">
                  <a onClick={() => handleModalOpen('city')} className="search_box--conditions--list-link">
                    <span className="search_box--conditions--list-link--icon01"><i className="fas fa-map-marker-alt" /></span>
                    <span className="search_box--conditions--list-link--text">市区町村から選択</span>
                    <SearchCondition names={[
                      ...filterPrefectures(prefectures, searchParams.prefecture_code).map(pref => pref.name),
                      ...cityConditionTexts
                    ]} />
                    <span className="search_box--conditions--list-link--icon02"><i className="fas fa-chevron-right" /></span>
                  </a>
                </li>
              ) : ''}
              {searchType === 'station' ? (
                <li className="search_box--conditions--list-item">
                  <a onClick={() => handleModalOpen('station')} className="search_box--conditions--list-link">
                    <span className="search_box--conditions--list-link--icon01"><i className="fas fa-train" /></span>
                    <span className="search_box--conditions--list-link--text">駅から選択</span>
                    <SearchCondition names={[
                      ...lineConditionTexts,
                      ...stationConditionTexts,
                    ]} />
                    <span className="search_box--conditions--list-link--icon02"><i className="fas fa-chevron-right" /></span>
                  </a>
                </li>
              ) : ''}
              {searchType === 'line-station' || searchType === 'city' ? (
                <li className="search_box--conditions--list-item">
                  <a onClick={() => handleModalOpen('line-station')} className="search_box--conditions--list-link">
                    <span className="search_box--conditions--list-link--icon01"><i className="fas fa-train" /></span>
                    <span className="search_box--conditions--list-link--text">駅・沿線から選択</span>
                    <SearchCondition names={[
                      ...lineConditionTexts,
                      ...stationConditionTexts,
                    ]} />
                    <span className="search_box--conditions--list-link--icon02"><i className="fas fa-chevron-right" /></span>
                  </a>
                </li>
              ) : ''}
              <li className="search_box--conditions--list-item">
                <a onClick={() => handleModalOpen('employment')} className="search_box--conditions--list-link">
                  <span className="search_box--conditions--list-link--icon01"><i className="fas fa-check-square" /></span>
                  <span className="search_box--conditions--list-link--text">雇用形態・給与から選択</span>
                  <SearchCondition
                    names={[
                      ...employmentConditionTexts,
                      ...salaryConditionTexts
                    ]}
                  />
                  <span className="search_box--conditions--list-link--icon02"><i className="fas fa-chevron-right" /></span>
                </a>
              </li>
              <li className="search_box--conditions--list-item">
                <a onClick={() => handleModalOpen('feature')} className="search_box--conditions--list-link">
                  <span className="search_box--conditions--list-link--icon01"><i className="fas fa-list" /></span>
                  <span className="search_box--conditions--list-link--text">特徴から選択</span>
                  <SearchCondition names={featureConditionTexts} />
                  <span className="search_box--conditions--list-link--icon02"><i className="fas fa-chevron-right" /></span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <SearchModals
          searchType={searchType}
          isModalOpen={isModalOpen}
          searchModalContentType={searchModalContentType}
          handleModalClose={handleModalClose}
        />
      </form>
    </CityLineSearchContext.Provider>
  );
};

export default CityLineSearchForm;
