import originalAxios, {AxiosInstance} from 'axios';

export const csrfToken = (): string => {
  const meta = document.querySelector('meta[name=csrf-token]') as Element;
  return meta['content']
};

export const axios = (): AxiosInstance => {
  const token = csrfToken();
  return originalAxios.create({
    timeout: 10000,
    headers: {
      'X-CSRF-Token': token,
    },
  })
};
