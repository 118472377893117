import * as React from 'react';

type Props = {
  names: string[];
}

const SearchCondition: React.FC<Props> = ({names}) => {
  if (!names.length) {
    return null;
  }
  return (
    <ul className="search_box--conditions--list-now">
      {names.slice(0, 3).map((name, i) => (
        <li key={i} className="search_box--conditions--list-now--item">{name}</li>
      ))}
      {names.length > 3 && (
        <li className="search_box--conditions--list-now--item">...</li>
      )}
    </ul>
  );
};

export default SearchCondition;
