import * as React from 'react';
import {filterEmploymentFeatures} from '../../../../lib/utils/search';
import FeatureListItem from './FeatureListItem';
import {PrefectureSearchContext} from '../SearchContext';

type Props = {
  isShow: boolean;
  handleCloseModal: () => void;
}

const EmploymentModalContent: React.FC<Props> = (
  {
    isShow,
    handleCloseModal,
  }
  ) => {
  const {features, salaryChoices, searchParams} = React.useContext(PrefectureSearchContext);

  return (
    <div className={`search_index-view ${isShow ? 'search_index-view-active' : ''}`}>
    <div className="c-overlay search_index-modal-overlay js-overlay" style={isShow ? {display: 'block'} : {}} onClick={() => handleCloseModal()}/>
      <div className={`search_index-modal ${isShow ? 'search_index-modal-show' : ''}`}>
        <div className="search_index-modal-frame">
          <div className="search_index-modal-body">
            <div className="search_index-modal-head forSp">雇用形態・給与から選択</div>
            <a aria-label="閉じる" className="forSp search_index-modal-close_btn" onClick={() => handleCloseModal()}>×</a>
            <div className="search_index-modal-content">
              <div className="search_index-modal-content-inner">
                <h4>雇用形態</h4>
                <ul className="search_index-modal-content-chk_list">
                  {filterEmploymentFeatures(features).map((feature, i) => (
                    <FeatureListItem key={i} feature={feature}/>
                  ))}
                </ul>
              </div>
              <div className="search_index-modal-content-inner">
                <h4>給与</h4>
                <div className="search_index-modal-content-salary">
                  {salaryChoices.yearly_salary_min && (
                    <div className="search_index-modal-content-salary--item">
                      <span>年収</span>
                      <div className="form_slct_arrow">
                        <select name="yearly_salary_min" defaultValue={searchParams.yearly_salary_min}>
                          <option value="">選択してください</option>
                          {salaryChoices.yearly_salary_min.map((value, i) => (
                            <option key={i} value={value[1]}>{value[0]}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  )}
                  {salaryChoices.monthly_salary_min && (
                    <div className="search_index-modal-content-salary--item">
                      <span>月収</span>
                      <div className="form_slct_arrow">
                        <select name="monthly_salary_min" defaultValue={searchParams.monthly_salary_min}>
                          <option value="">選択してください</option>
                          {salaryChoices.monthly_salary_min.map((value, i) => (
                            <option key={i} value={value[1]}>{value[0]}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  )}
                  {salaryChoices.daily_salary_min && (
                    <div className="search_index-modal-content-salary--item">
                      <span>日給</span>
                      <div className="form_slct_arrow">
                        <select name="daily_salary_min" defaultValue={searchParams.daily_salary_min}>
                          <option value="">選択してください</option>
                          {salaryChoices.daily_salary_min.map((value, i) => (
                            <option key={i} value={value[1]}>{value[0]}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  )}
                  {salaryChoices.hourly_salary_min && (
                    <div className="search_index-modal-content-salary--item">
                      <span>時給</span>
                      <div className="form_slct_arrow">
                        <select name="hourly_salary_min" defaultValue={searchParams.hourly_salary_min}>
                          <option value="">選択してください</option>
                          {salaryChoices.hourly_salary_min.map((value, i) => (
                            <option key={i} value={value[1]}>{value[0]}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="search_index-modal-foot">
              <div className="search_index-modal-foot-content">
                <div className="search_index-modal-foot--btn_wrap">
                  <button type="submit" name="button" className="search_index-modal-foot--submit_btn">求人を検索する</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default EmploymentModalContent;
