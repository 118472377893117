import * as React from 'react';
import {
  CounterInterface,
  OccupationAsciiType,
  SalaryChoiceInterface, SearchCityModel, SearchFormValuesInterface, SearchParamInterface, normalizedUrlParamInterface,
  SearchPrefectureOptionInterface
} from '../../../lib/interfaces/search';
import { FeatureModel, RailroadLineModel } from '../../../lib/interfaces/models';

type PrefectureSearchContextType = {
  occupationAscii: OccupationAsciiType;
  searchParams: SearchParamInterface;
  normalizedUrlParams: normalizedUrlParamInterface;
  prefectures: SearchPrefectureOptionInterface[];
  features: FeatureModel[];
  salaryChoices: SalaryChoiceInterface;
  counter?: CounterInterface;
}

type CityLineSearchContextType = PrefectureSearchContextType & {
  cities: SearchCityModel[];
  railroadLines: RailroadLineModel[];
  formValues: SearchFormValuesInterface;
  setFormValues: (formValues: SearchFormValuesInterface) => void;
}

export const PrefectureSearchContext = React.createContext<PrefectureSearchContextType>({
  occupationAscii: 'dh',
  searchParams: {},
  normalizedUrlParams: {},
  prefectures: [],
  features: [],
  salaryChoices: { yearly_salary_min: [], monthly_salary_min: [], daily_salary_min: [], hourly_salary_min: [] },
});

export const CityLineSearchContext = React.createContext<CityLineSearchContextType>({
  occupationAscii: 'dh',
  searchParams: {},
  normalizedUrlParams: {},
  prefectures: [],
  features: [],
  salaryChoices: { yearly_salary_min: [], monthly_salary_min: [], daily_salary_min: [], hourly_salary_min: [] },
  cities: [],
  railroadLines: [],
  formValues: {},
  setFormValues: () => false
});

