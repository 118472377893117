import * as React from 'react';
import {CityLineSearchContext} from '../SearchContext';
import LineListItem from './LineListItem';

type Props = {
}

const LineModalContent: React.FC<Props> = () => {
  const {railroadLines} = React.useContext(CityLineSearchContext);
  return (
    <div className="search_box-modal-content-inner">
      <ul className="search_box-modal-content-chk_list">
        {railroadLines.map((line, i) => (
          <LineListItem key={i} railroadLine={line}/>
        ))}
      </ul>
    </div>
  )
};

export default LineModalContent;
