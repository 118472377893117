export const gaClickEvent = (category: string, label: string | null): void => {
  console.log(`ga-click(Action:Click, Label:${label}, Category:${category})`);

  let contents = {'eventCategory': category, 'eventAction': 'Click'};
  if (label) {
    contents['eventLabel'] = label;
  }

  if (window.ga as UniversalAnalytics.ga) {
    window.ga('send', 'event', contents);
  }
};
