import * as React from 'react';
import * as _ from 'lodash';
import {CityLineSearchContext} from '../SearchContext';
import StationListItem from './StationListItem';
import SearchAccordion from './SearchAccordion';
import {calcDistance} from '../../../../lib/utils';
import {filterCities} from '../../../../lib/utils/search';

type Props = {
}

const LineStationModalContent: React.FC<Props> = () => {
  const {railroadLines, searchParams, cities} = React.useContext(CityLineSearchContext);

  const groupStationLines = React.useMemo(() => {
    return _.filter(railroadLines, (line) => {
      return !!(line.railroad_stations &&
        line.railroad_stations.findIndex(station => {

          if (searchParams.station_group_id) {
            return _.flatten([searchParams.station_group_id]).includes(station.station_group_id)
          } else {
            const selectedCities = filterCities(cities, searchParams.city_code);
            let distance: number | undefined = undefined;
            selectedCities.forEach((city) => {
              if (station.lat && station.lon && city.lat && city.lon) {
                const dis = calcDistance(station.lat, station.lon, city.lat, city.lon)
                if (distance === undefined || dis < distance) {
                  distance = dis
                }
              }
            });

            return distance && distance < 5;
          }
        }) != -1)
    });
  }, [railroadLines, searchParams, cities]);

  return (
    <React.Fragment>
      {groupStationLines.length > 0 && (
        <React.Fragment>
          <h5>関連する駅・沿線</h5>
          {groupStationLines.map((railroadLine, i) => (
            <SearchAccordion key={i} title={railroadLine.name} isInitialOpen={!!searchParams.station_group_id}>
              <ul className="search_box-modal-content-chk_list">
                {railroadLine.railroad_stations?.map((station, i) => (
                  <StationListItem key={i} railroadStation={station}/>
                ))}
              </ul>
            </SearchAccordion>
          ))}
        </React.Fragment>
        )}
      <h5>同じ都道府県の駅・沿線</h5>
      {railroadLines.map((railroadLine, i) => (
        <SearchAccordion key={i} title={railroadLine.name}>
          <ul className="search_box-modal-content-chk_list">
            {railroadLine.railroad_stations?.map((station, i) => (
              <StationListItem key={i} railroadStation={station}/>
            ))}
          </ul>
        </SearchAccordion>
      ))}
    </React.Fragment>
  )
};

export default LineStationModalContent;
