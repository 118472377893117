import * as React from 'react';
import {SearchModalContentTypeInterface} from '../../../../lib/interfaces/search';

type Props = {
  isOpen: boolean;
  searchModalContentType: SearchModalContentTypeInterface | undefined
  handleModalClose: () => void;
}

const SearchModal: React.FC<Props> = ({isOpen, handleModalClose, searchModalContentType, children}) => {
  const [title, setTitle] = React.useState('');

  React.useEffect(() => {
    switch (searchModalContentType) {
      case 'prefecture':
        setTitle('都道府県');
        break;
      case 'city':
        setTitle('市区町村');
        break;
      case 'line-station':
        setTitle('駅・沿線');
        break;
      case 'line':
        setTitle('沿線');
        break;
      case 'station':
        setTitle('駅');
        break;
      case 'employment':
        setTitle('雇用形態・給与');
        break;
      case 'feature':
        setTitle('特徴');
        break;
      default:
        setTitle('')
    }
  }, [searchModalContentType]);

  return (
    <React.Fragment>
      <div className="c-overlay search_box-modal-overlay js-overlay" style={isOpen ? {display: 'block'} : {}} onClick={() => handleModalClose()}/>
      <div className={`search_box-modal ${isOpen ? 'search_box-modal-show' : ''}`}>
        <div className="search_box-modal-frame">
          <div className="search_box-modal-body">
            <div className="search_box-modal-head"><span className="search_box-modal-head--title">{title}から選択</span></div>
            <a onClick={() => handleModalClose()} aria-label="閉じる" className="search_box-modal-close_btn">×</a>
            <div className="search_box-modal-content">
              {children}
            </div>
            <div className="search_box-modal-foot">
              <div className="search_box-modal-foot-content">
                <div className="search_box-modal-foot--btn_wrap">
                  <button type="submit" name="button" className="search_box-modal-foot--submit_btn">求人を検索する</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SearchModal;
