import * as React from 'react';

type Props = {
  title: string;
  isInitialOpen?: boolean;
};

const SearchAccordion: React.FC<Props> = (
  {title, isInitialOpen = false, children}
) => {
  const [isOpen, toggleOpen] = React.useState(isInitialOpen);

  return (
    <div className="search_box-modal-accordion">
      <div className="search_box-modal-accordion--item">
        <a className="search_box-modal-accordion--item-title" onClick={() => toggleOpen(!isOpen)}>
          <span className="search_box-modal-accordion--item-title-text">{title}</span>
          <span className="search_box-modal-accordion--item-title-icon"><i className={`fas fa-angle-${isOpen ? 'up' : 'down'}`}/></span>
        </a>
        <div className="search_box-modal-accordion--content" style={isOpen ? {} : {display: 'none'}}>
          <div className="search_box-modal-content-inner">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchAccordion;
