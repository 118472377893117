import * as React from 'react';
import * as _ from 'lodash';
import { FeatureModel } from '../../../../lib/interfaces/models';
import { searchLink } from '../../../../lib/utils/link';
import { CityLineSearchContext } from '../SearchContext';

type Props = {
  feature: FeatureModel;
}

const FeatureListItem: React.FC<Props> = ({ feature }) => {
  const { searchParams, normalizedUrlParams, occupationAscii, formValues, setFormValues } = React.useContext(CityLineSearchContext);

  const handleChange = React.useCallback((featureId: number, checked: boolean) => {
    const newFormValues = Object.assign({}, formValues);

    let formFeatureId = _.compact(_.flatten([newFormValues.feature_id])) as number[];
    if (checked) {
      formFeatureId.push(featureId);
    } else {
      _.remove(formFeatureId, (v) => v === featureId)
    }
    newFormValues.feature_id = formFeatureId;

    setFormValues(newFormValues)
  }, [formValues, setFormValues]);

  return (
    <li className="search_box-modal-content-chk_list-item">
      <div className="search_box-modal-group_link">
        <label className="search_box-modal-group_link-chk_box">
          <input
            type="checkbox"
            name="feature_id[]"
            value={feature.id}
            onChange={(e) => handleChange(feature.id, e.target.checked)}
            checked={_.flatten([formValues.feature_id]).includes(feature.id)}
          />
          <span className="search_box-modal-group_link-chk_style" />
          <span className="search_box-modal-group_link-title">{feature.name}</span>
        </label>
        <a
          aria-label={feature.name}
          href={searchLink(
            occupationAscii,
            Object.assign({ feature_id: feature.id }, _.omit(searchParams, ['occupation_ascii', 'feature_id'])),
            Object.assign({ feature_ascii: feature.ascii }, _.omit(normalizedUrlParams, ['feature_ascii'])),
          )}
        >
          <i className="fas fa-chevron-right" />
        </a>
      </div>
    </li>
  )
};

export default FeatureListItem;
