import { gaClickEvent } from '../../../lib/utils/ga_util';
import * as React from 'react';
import Modal from '../shared/Modal';

export type Props = {
  glomouthLineAccountType: 'career_line' | 'crm_line';
};

const DhCompleteModal: React.FC<Props> = ({
  glomouthLineAccountType
}) => {
  const [showable, setShowable] = React.useState<boolean>(true);

  return (
    <Modal
      showable={showable}
      isClose={() => setShowable(false)}
    >
      <React.Fragment>
        <a
          onClick={() => gaClickEvent('Complete-Line-Modal', glomouthLineAccountType === 'career_line' ? 'Career' : 'Crm')}
          href={glomouthLineAccountType === 'career_line' ? 'https://line.me/ti/p/GZrB6Jv_7j' : 'https://line.me/ti/p/uI9b7UY1wK'}
          target="_blank"
          rel="noreferrer"
          style={{display: 'flex', justifyContent: 'center'}}
        >
          <img src='/static/img/dh/lp/comp_line_register_modal.png' alt="希望条件を入力する" className="banner_size" />
        </a>
      </React.Fragment>
    </Modal>
  );
};

export default DhCompleteModal;
