import * as React from 'react';
import {CityLineSearchContext} from '../SearchContext';
import {
  filterClinicEnvironmentFeatures, filterClinicPolicyFeatures,
  filterMedicalApartmentFeatures,
  filterWorkingConditionFeatures
} from '../../../../lib/utils/search';
import FeatureListItem from './FeatureListItem';

type Props = {
}

const FeatureModalContent: React.FC<Props> = () => {
  const {occupationAscii, features} = React.useContext(CityLineSearchContext);

  return (
    <React.Fragment>
      <div className="search_box-modal-content-inner">
        <h4>勤務条件</h4>
        <ul className="search_box-modal-content-chk_list">
          {filterWorkingConditionFeatures(features).map((feature, i) => (
            <FeatureListItem key={i} feature={feature}/>
          ))}
        </ul>
      </div>
      <div className="search_box-modal-content-inner">
        <h4>医院の環境</h4>
        <ul className="search_box-modal-content-chk_list">
          {filterClinicEnvironmentFeatures(features).map((feature, i) => (
            <FeatureListItem key={i} feature={feature}/>
          ))}
        </ul>
      </div>
      <div className="search_box-modal-content-inner">
        <h4>診療内容</h4>
        <ul className="search_box-modal-content-chk_list">
          {filterMedicalApartmentFeatures(features).map((feature, i) => (
            <FeatureListItem key={i} feature={feature}/>
          ))}
        </ul>
      </div>
      {occupationAscii === 'dr' && (
        <div className="search_box-modal-content-inner">
          <h4>診療方針</h4>
          <ul className="search_box-modal-content-chk_list">
            {filterClinicPolicyFeatures(features).map((feature, i) => (
              <FeatureListItem key={i} feature={feature}/>
            ))}
          </ul>
        </div>
      )}
    </React.Fragment>
  )
};

export default FeatureModalContent;
