import * as React from 'react';
import {
  CounterInterface,
  OccupationAsciiType,
  SalaryChoiceInterface,
  SearchParamInterface,
  normalizedUrlParamInterface,
  SearchPrefectureOptionInterface
} from '../../../lib/interfaces/search';
import {
  FeatureModel,
} from '../../../lib/interfaces/models';
import PrefectureModalContent from './prefecture/PrefectureModalContent';
import EmploymentModalContent from './prefecture/EmploymentModalContent';
import FeatureModalContent from './prefecture/FeatureModalContent';
import { PrefectureSearchContext } from './SearchContext';

type Props = {
  occupationAscii: OccupationAsciiType;
  searchParams: SearchParamInterface;
  normalizedUrlParams: normalizedUrlParamInterface;
  prefectures: SearchPrefectureOptionInterface[];
  features: FeatureModel[];
  salaryChoices: SalaryChoiceInterface;
  counter?: CounterInterface;
}

export const initialProps: Props = {
  occupationAscii: 'dh',
  searchParams: {},
  normalizedUrlParams: {},
  prefectures: [],
  features: [],
  salaryChoices: { yearly_salary_min: [], monthly_salary_min: [], daily_salary_min: [], hourly_salary_min: [] }
};

type TabType = 'prefecture' | 'employment' | 'feature';

const PrefectureSearchForm: React.FC<Props> = (
  {
    occupationAscii,
    searchParams,
    normalizedUrlParams,
    prefectures,
    features,
    salaryChoices,
    counter,
  }
) => {

  const [tab, switchTab] = React.useState<TabType>('prefecture');
  const formRef = React.createRef<HTMLFormElement>();

  const handleSwitchTab = React.useCallback((tabType: TabType) => {
    if (window.parent.screen.width <= 600) {
      if (tabType === 'employment' || tabType === 'feature') {
        document.body.style.cssText = 'overflow: hidden;';
      } else {
        document.body.style.cssText = '';
      }
    }
    switchTab(tabType);
    if (tab !== tabType && formRef.current) {
      formRef.current.reset();
    }

  }, [tab, formRef]);

  return (
    <PrefectureSearchContext.Provider
      value={{
        occupationAscii: occupationAscii,
        searchParams: searchParams,
        normalizedUrlParams: normalizedUrlParams,
        prefectures: prefectures,
        features: features,
        salaryChoices: salaryChoices,
        counter: counter
      }}
    >
      <form ref={formRef} action={`/${occupationAscii}/job/search`} method={'get'}>
        <div className="search_index">
          <div className="search_index-container width">
            <div className="search_index-tabs">
              <div
                className={`search_index-tabs--item search_index-tabs--item-genre_area ${tab === 'prefecture' ? 'search_index-tabs--item-active' : ''}`}
                onClick={() => handleSwitchTab('prefecture')}
              >
                <div className="search_index-tabs--item-link">
                  <div>
                    <span className="search_index-tabs--item-icon"><i className="fas fa-map-marker-alt" /></span>
                    <span className="search_index-tabs--item-ttl">都道府県から選択</span>
                  </div>
                </div>
              </div>
              <PrefectureModalContent isShow={tab === 'prefecture'} />
              <div
                className={`search_index-tabs--item search_index-tabs--item-genre_salary ${tab === 'employment' ? 'search_index-tabs--item-active' : ''}`}
                onClick={() => handleSwitchTab('employment')}
              >
                <div className="search_index-tabs--item-link">
                  <div>
                    <span className="search_index-tabs--item-icon"><i className="fas fa-check-square" /></span>
                    <span className="search_index-tabs--item-ttl">雇用形態・給与から選択</span>
                    <span className="search_index-tabs--item-arrow"><i className="fas fa-chevron-right" /></span>
                  </div>
                </div>
              </div>
              <EmploymentModalContent
                isShow={tab === 'employment'}
                handleCloseModal={() => handleSwitchTab('prefecture')}
              />
              <div
                className={`search_index-tabs--item search_index-tabs--item-genre_feature ${tab === 'feature' ? 'search_index-tabs--item-active' : ''}`}
                onClick={() => handleSwitchTab('feature')}
              >
                <div className="search_index-tabs--item-link">
                  <div>
                    <span className="search_index-tabs--item-icon"><i className="fas fa-list" /></span>
                    <span className="search_index-tabs--item-ttl">特徴から選択</span>
                    <span className="search_index-tabs--item-arrow"><i className="fas fa-chevron-right" /></span>
                  </div>
                </div>
              </div>
              <FeatureModalContent
                isShow={tab === 'feature'}
                handleCloseModal={() => handleSwitchTab('prefecture')}
              />
            </div>
            <div className="search_index-free_search">
              <div className="search_index-free_search-inner">
                <div className="search_index-free_search-input">
                  <input type="input" name="word" placeholder="駅の名前、医院名、特徴など" defaultValue={searchParams.word || ''} />
                </div>
                <div className="search_index-free_search-submit">
                  <button type="submit" name=""><i className="fas fa-search" /></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </PrefectureSearchContext.Provider>
  );
};

export default PrefectureSearchForm;
