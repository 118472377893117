import * as React from 'react';
import { axios } from '../../../lib/utils/axios';
import { gaClickEvent } from '../../../lib/utils/ga_util';
import Modal from '../shared/Modal';

export type Props = {
  desired_conditions_url: string;
  serial: string;
};

const LineIdModal: React.FC<Props> = ({
  desired_conditions_url,
  serial
}) => {
  const [showable, setShowable] = React.useState<boolean>(true);
  const [lineId, setLineId] = React.useState<string>('');
  const [modalPageNo, setModalPageNo] = React.useState<number>(1);
  const [completed, setCompleted] = React.useState<boolean>(false);
  const [errors, setErrors] = React.useState<string>('');

  const handleSubmit = React.useCallback(async (): Promise<void> => {
    if (!lineId) {
      setErrors('LINE IDを入力してください');
      return;
    }

    const res = await axios().post('/api/line/register', {
      serial: serial,
      line_id_register: lineId
    });
    if (res.data.errors) {
      setErrors(res.data.errors);
      return;
    }

    setModalPageNo(3);

    setTimeout(() => {
      setShowable(false)
    }, 5000);
  }, [lineId, showable]);

  return (
    <Modal
      showable={showable}
      isClose={() => setShowable(false)}
      gaClickEvent={() => gaClickEvent('line-id-modal-close', null)}
    >
          {/* オンライン就活フェア終了後に実装 */}
          {/* <div className="form_complete--modal--line_id--comp_wrap">
            <div className="form_complete--modal--line_id--comp--wait">
              <img src="/static/img/loading.gif" alt="" />
            </div>
            <div className="form_complete--modal--line_id--comp">
              <p className="margin_none">LINE IDの入力ありがとうございます！</p>
              <p>次に詳細な希望条件を教えてください。</p>
              <a href={desired_conditions_url} target="_blank">希望条件を入力する</a>
            </div>
          </div> */}
          {/* オンライン就活フェア終了後に実装 */}

          <div className="form_complete--modal--line_id--app">
            <div className="form_complete--modal--line_id--app--camp">
              { [1, 2].includes(modalPageNo) && (
                <div className="camp_head">
                  <p className="camp_text">
                    <span className="bold">学生キャンペーン</span><br />
                    第28回〜第30回国試に合格した<br />
                    <span className="underline">先輩の国試対策ノート画像を</span>LINEでプレゼント中！
                  </p>
                </div>
              )}
              <div className="form_complete--modal--line_id--app--input">
                { modalPageNo === 1 && (
                  <React.Fragment>
                    <div>
                      <p className="camp_text">
                        ～貰える画像の一例～
                      </p>
                    </div>
                    <div className="camp_image">
                      <img src="/dh/lp/complete/line_present.jpg" alt="" />
                    </div>
                    <div className="form_complete--modal--line_id--app--input">
                      <button
                        className="form_complete--modal--line_id--app--input--submit"
                        onClick={() => {
                          setModalPageNo(2);
                          gaClickEvent('line-id-modal-id-add-1', null);
                        }}
                      >
                        プレゼントをLINEで受け取る
                      </button>
                    </div>
                  </React.Fragment>
                )}
                { modalPageNo === 2 && (
                  <React.Fragment>
                    <label htmlFor="line_id" className="form_complete--modal--line_id--app--input--label">LINE ID</label>
                    <input
                      type="text"
                      id="line_id"
                      className="form_complete--modal--line_id--app--input--text"
                      value={lineId}
                      placeholder="LINE IDを入力してください"
                      onChange={(e) => setLineId(e.target.value)}
                    />
                    <div className="form_complete--modal--line_id--app--input">
                      <button
                        className="form_complete--modal--line_id--app--input--submit"
                        onClick={() => {
                          handleSubmit();
                          gaClickEvent('line-id-modal-id-add-2', null);
                        }}
                      >
                        LINE IDを追加して<span className="br_sp">プレゼント画像を受け取る</span>
                      </button>
                      <pre style={{color: 'red'}}>{errors}</pre>
                    </div>
                  </React.Fragment>
                )}
                { modalPageNo === 3 && (
                  <React.Fragment>
                    <div>
                      <p className="camp_text end">
                        LINE IDを追加いただき<span className="br_sp">ありがとうございます。</span>
                      </p>
                      <p className="camp_text end">
                        プレゼント画像は<span className="br_sp">当社アカウント</span>追加後に<br />
                        LINEトーク上で<span className="br_sp">お送りいたします。</span>
                      </p>
                    </div>
                    <div className="form_complete--modal--line_id--app--input">
                      <button
                        className="form_complete--modal--line_id--app--input--submit close"
                        onClick={() => {
                          setShowable(false);
                          gaClickEvent('line-id-modal-id-add-3', null);
                        }}
                      >
                        閉じる
                      </button>
                    </div>
                  </React.Fragment>
                )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default LineIdModal;
