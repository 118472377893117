import * as React from 'react';
import {filterEmploymentFeatures} from '../../../../lib/utils/search';
import FeatureListItem from './FeatureListItem';
import {CityLineSearchContext} from '../SearchContext';

type Props = {}

const EmploymentModalContent: React.FC<Props> = () => {
  const {features, salaryChoices, formValues, setFormValues} = React.useContext(CityLineSearchContext);
  const handleChangeSalary = React.useCallback((key: string, value: string) => {
    const newFormValues = Object.assign({}, formValues);

    newFormValues[key] = value;
    setFormValues(newFormValues)
  }, [formValues, setFormValues]);

  return (
    <React.Fragment>
      <div className="search_box-modal-content-inner">
        <h4>雇用形態</h4>
        <ul className="search_box-modal-content-chk_list">
          {filterEmploymentFeatures(features).map((feature, i) => (
            <FeatureListItem key={i} feature={feature}/>
          ))}
        </ul>
      </div>
      <div className="search_box-modal-content-inner">
        <h4>給与</h4>
        <div className="search_box-modal-content-salary">
          {salaryChoices.yearly_salary_min && (
            <div className="search_box-modal-content-salary--item">
              <span>年収</span>
              <div className="form_slct_arrow">
                <select
                  name="yearly_salary_min"
                  onChange={(e) => handleChangeSalary('yearly_salary_min', e.target.value)}
                  value={formValues.yearly_salary_min || ''}
                >
                  <option value="">選択してください</option>
                  {salaryChoices.yearly_salary_min.map((value, i) => (
                    <option key={i} value={value[1]}>{value[0]}</option>
                  ))}
                </select>
              </div>
            </div>
          )}
          {salaryChoices.monthly_salary_min && (
            <div className="search_box-modal-content-salary--item">
              <span>月収</span>
              <div className="form_slct_arrow">
                <select
                  name="monthly_salary_min"
                  onChange={(e) => handleChangeSalary('monthly_salary_min', e.target.value)}
                  value={formValues.monthly_salary_min || ''}
                >
                  <option value="">選択してください</option>
                  {salaryChoices.monthly_salary_min.map((value, i) => (
                    <option key={i} value={value[1]}>{value[0]}</option>
                  ))}
                </select>
              </div>
            </div>
          )}
          {salaryChoices.daily_salary_min && (
            <div className="search_box-modal-content-salary--item">
              <span>日給</span>
              <div className="form_slct_arrow">
                <select
                  name="daily_salary_min"
                  onChange={(e) => handleChangeSalary('daily_salary_min', e.target.value)}
                  value={formValues.daily_salary_min || ''}
                >
                  <option value="">選択してください</option>
                  {salaryChoices.daily_salary_min.map((value, i) => (
                    <option key={i} value={value[1]}>{value[0]}</option>
                  ))}
                </select>
              </div>
            </div>
          )}
          {salaryChoices.hourly_salary_min && (
            <div className="search_box-modal-content-salary--item">
              <span>時給</span>
              <div className="form_slct_arrow">
                <select
                  name="hourly_salary_min"
                  onChange={(e) => handleChangeSalary('hourly_salary_min', e.target.value)}
                  value={formValues.hourly_salary_min || ''}
                >
                  <option value="">選択してください</option>
                  {salaryChoices.hourly_salary_min.map((value, i) => (
                    <option key={i} value={value[1]}>{value[0]}</option>
                  ))}
                </select>
              </div>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  )
};

export default EmploymentModalContent;
